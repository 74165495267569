// // User settings are set for each user. Each user can have multiple settings for different stores.
// type BrandSettings struct {
// 	BrandStyle BrandStyle `json:"style,omitempty"`
// 	// Page specific settings/styles
// 	LandingPage LandingPageSettings `json:"landing_page,omitempty"`
// 	CaptchaPage CaptchaPageSettings `json:"captcha_page,omitempty"`
// }

export type BrandSettings = {
    style?: BrandStyle;
    landing_page?: LandingPageSettings;
    captcha_page?: CaptchaPageSettings;
}

// type BrandStyle struct {
// 	BrandColorPallet `json:",inline"`
// 	TextStyles       `json:",inline"`

// 	AvatarLogo      string `json:"avatar_logo,omitempty"`
// 	CoverBackground string `json:"cover_background,omitempty"`

// 	Input           InputStyle  `json:"input,omitempty"`
// 	ButtonPrimary   ButtonStyle `json:"button_primary,omitempty"`
// 	ButtonSecondary ButtonStyle `json:"button_secondary,omitempty"`
// 	ButtonSpecial   ButtonStyle `json:"button_special,omitempty"`
// }

export type BrandStyle = BrandColorPallet & TextStyles & {
    avatar_logo?: string;
    cover_background?: string;
    input?: InputStyle;
    button_primary?: ButtonStyle;
    button_secondary?: ButtonStyle;
    button_special?: ButtonStyle;
}

// const (
// 	// Landing page variants
// 	LandingVariant_Modern   = "modern"
// 	LandingVariant_LinkTree = "linkTree"

// 	// Landing identification methods
// 	LandingIdentificationMethod_AgeGate = "age_gate"

// 	LandingPageButtonLinkShopOnline   = "shopOnline"
// 	LandingPageButtonLinkDirections   = "directions"
// 	LandingPageButtonLinkCallUS       = "callUs"
// 	LandingPageButtonLinkMemberWallet = "memberWallet"
// )

// var AllLandingPageButtons = otk.NewSet(
// 	LandingPageButtonLinkShopOnline,
// 	LandingPageButtonLinkDirections,
// 	LandingPageButtonLinkCallUS,
// 	LandingPageButtonLinkMemberWallet,
// )


export const LandingVariants = {
    modern: {
        title: 'Modern style',
        description: 'Drive more customers to engage with your intended CTA via a clean non-distracting design',
        isEnabled: (brandSettings?: Partial<BrandSettings>) => {
            return brandSettings?.landing_page?.variant === 'modern' || !brandSettings?.landing_page?.variant;
        },
    },
    'linkTree': {
        title: 'Linktree style',
        description: 'Use full width large buttons',
        isEnabled: (brandSettings?: Partial<BrandSettings>) => {
            return brandSettings?.landing_page?.variant === 'linkTree';
        },
    }
} as const

export type LandingVariant = keyof typeof LandingVariants;

export const IdentifiationMethods = ["age-gate", "multiple-choice"] as const;
export type IdentifiationMethod = typeof IdentifiationMethods[number];

export const LandingPageButtonLinks = {
    shopOnline: {
        title: 'Shop Online',
        description: 'Link to your online store',
    },
    directions: {
        title: 'Directions',
        description: 'Link to your location on a map',
    },
    callUs: {
        title: 'Call Us',
        description: 'Link to a phone number',
    },
    memberWallet: {
        title: 'Member Wallet',
        description: 'Link to a member wallet page',
    }
} as const

export type LandingPageButtonLinkType = keyof typeof LandingPageButtonLinks;

// type LandingPageSettings struct {
// 	Variant       string                           `json:"variant,omitempty"`
// 	CaptchaMethod string                           `json:"captcha_method,omitempty"`
// 	ButtonsMap    map[string]LandingPageButtonLink `json:"buttons_map,omitempty"`
// 	StyleConfig   BrandStyle                       `json:"style_config,omitempty"`
// }

export type LandingPageSettings = {
    variant?: LandingVariant;
    captcha_method?: IdentifiationMethod;
    buttons_map?: Record<LandingPageButtonLinkType, LandingPageButtonLink>;
    style_config?: BrandStyle;
}

// type LandingPageButtonLink struct {
// 	Text    string `json:"text,omitempty"`
// 	URL     string `json:"url,omitempty"`
// 	Enabled bool   `json:"enabled,omitempty"`
// }

export type LandingPageButtonLink = {
    text?: string;
    url?: string;
    enabled?: boolean;
}

// type CaptchaPageSettings struct {
// 	Methods     CaptchaPageMethods `json:"methods,omitempty"`
// 	StyleConfig BrandStyle         `json:"style_config,omitempty"`
// }

export type CaptchaPageSettings = {
    methods?: Record<string, CaptchaPageMethod>;
    style_config?: BrandStyle;
}

// type CaptchaPageMethods struct {
// 	Pin   CaptchaPageMethod `json:"pin,omitempty"`
// 	DOB   CaptchaPageMethod `json:"dob,omitempty"`
// 	Phone CaptchaPageMethod `json:"phone,omitempty"`
// }

export type CaptchaPageMethods = {
    pin?: CaptchaPageMethod;
    dob?: CaptchaPageMethod;
    phone?: CaptchaPageMethod;
}

// type CaptchaPageMethod struct {
// 	Header TextContainer `json:"header,omitempty"`
// 	Button ButtonStyle   `json:"button,omitempty"`

// 	Enabled bool `json:"enabled,omitempty"`
// }

export type CaptchaPageMethod = {
    header?: TextContainer;
    button?: ButtonStyle;
    enabled?: boolean;
}

// type BrandColorPallet struct {
// 	BrandColor   string `json:"brand_color,omitempty"`
// 	LinkColor    string `json:"link_color,omitempty"`
// 	SuccessColor string `json:"success_color,omitempty"`
// 	WarningColor string `json:"warning_color,omitempty"`
// 	DangerColor  string `json:"danger_color,omitempty"`

// 	TextBrand     string `json:"text_brand,omitempty"`
// 	TextPrimary   string `json:"text_primary,omitempty"`
// 	TextSecondary string `json:"text_secondary,omitempty"`

// 	SurfacePrimary   string `json:"surface_primary,omitempty"`
// 	SurfaceSecondary string `json:"surface_secondary,omitempty"`

// 	BorderPrimary string `json:"border_primary,omitempty"`
// }

export type BrandColorPallet = {
    brand_color?: string;
    secondary_color?: string;
    special_color?: string;
    link_color?: string;
    success_color?: string;
    warning_color?: string;
    danger_color?: string;
    text_brand?: string;
    text_primary?: string;
    text_secondary?: string;
    surface_primary?: string;
    surface_secondary?: string;
    border_primary?: string;
}

// type InputStyle struct {
// 	TextContainer  `json:",inline"`
// 	Placeholder    string        `json:"placeholder,omitempty"`
// 	DisabledStyles TextContainer `json:"disabled,omitempty"`
// }

export type InputStyle = TextContainer & {
    placeholder?: string;
    disabled?: TextContainer;
}

// type ButtonStyle struct {
// 	TextContainer  `json:",inline"`
// 	Height         string        `json:"height,omitempty"`
// 	Width          string        `json:"width,omitempty"`
// 	DisabledStyles TextContainer `json:"disabled,omitempty"`
// }

export type ButtonStyle = TextContainer & {
    height?: string;
    width?: string;
    disabled?: TextContainer;
}

// type TextStyles struct {
// 	TextColor      string `json:"color,omitempty"`
// 	TextColorHover string `json:"color_hover,omitempty"`
// 	FontWeight     string `json:"font_weight,omitempty"`
// 	FontSize       string `json:"font_size,omitempty"`
// 	FontFamily     string `json:"font_family,omitempty"`
// }

export type TextStyles = {
    color?: string;
    color_hoverHover?: string;
    font_weight?: string;
    font_size?: string;
    font_family?: string;
}

// type TextContainer struct {
// 	ContainerStyles `json:",inline"`
// 	TextStyles      `json:",inline"`
// 	TextDefault     string `json:"text_default,omitempty"`
// 	TextAlt         string `json:"text_alt,omitempty"`
// 	TextSuccess     string `json:"text_success,omitempty"`
// 	TextLoading     string `json:"text_loading,omitempty"`
// }

export type TextContainer = ContainerStyles & TextStyles & {
    text_default?: string;
    text_alt?: string;
    text_success?: string;
    text_loading?: string;
}

// type ContainerStyles struct {
// 	Display              string `json:"display,omitempty"`
// 	FlexDir              string `json:"flex_dir,omitempty"`
// 	JustifyContent       string `json:"justify_content,omitempty"`
// 	AlignItems           string `json:"align_items,omitempty"`
// 	TextAlign            string `json:"text_align,omitempty"`
// 	Padding              string `json:"padding,omitempty"`
// 	BackgroundColor      string `json:"bg,omitempty"`
// 	BackgroundColorHover string `json:"bg_hover,omitempty"`
// 	BorderColor          string `json:"border_color,omitempty"`
// 	BorderColorHover     string `json:"border_color_hover,omitempty"`
// 	BorderRadius         string `json:"border_radius,omitempty"`
// }

export type ContainerStyles = {
    display?: string;
    flex_dir?: string;
    justify_content?: string;
    align_items?: string;
    text_align?: string;
    padding?: string;
    bg?: string;
    bg_hover?: string;
    border_color?: string;
    border_color_hover?: string;
    border_radius?: string;
}